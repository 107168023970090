import React, { type FunctionComponent, useContext, useState } from 'react';
import { TOOLTIP_DISPLAY_TIME } from '../../../constants/general';
import { CompareContext } from '../../../contexts/compare/compare.context';
import { CheckboxButton, type CheckboxButtonProps } from '../../buttons';
import { Popover } from '../../common-components/popover/popover.component';

export type CompareButtonProps = {
	variantId: number;
	onCompareStart?: () => void;
	onCompareStop?: () => void;
} & Omit<CheckboxButtonProps, 'onClick' | 'value' | 'label' | 'isChecked' | 'id'>;

export const CompareButton: FunctionComponent<CompareButtonProps> = ({ variantId, hasBorder = true, ...remainingProps }) => {
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const { isComparing, toggleProductCompare } = useContext(CompareContext);

	// Toggle comparison of product
	const doClick = () => {
		const isSuccess = toggleProductCompare(String(variantId));
		if (!isSuccess) {
			// Show compare-drawer full tooltip
			triggerTooltip();
		}
	};

	const triggerTooltip = () => {
		setTooltipVisible(true);
		setTimeout(() => setTooltipVisible(false), TOOLTIP_DISPLAY_TIME);
	};

	return (
		<Popover
			isVisible={tooltipVisible}
			direction={'top'}
			theme="dark"
			popOverClassName="br3 f7 ph1 pv2 z-2"
			toggleElement={
				<CheckboxButton
					id={`compare-variant-${variantId}`}
					label="Compare"
					value={`comparecheck_${variantId}`}
					automationHook="compare"
					isChecked={isComparing(String(variantId))}
					onClick={doClick}
					hasBorder={hasBorder}
					{...remainingProps}
				/>
			}>
			<span className="w4 lh-copy ws-normal tc">You may only compare 4 products at once</span>
		</Popover>
	);
};
